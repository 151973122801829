<template>
  <div class="connent_title">
    <div class="connent_header">
      <span><a href="/BusinessManagement/BusinessManagement/List" >企业管理</a></span>
    </div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>列表</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/BusinessManagement/BusinessManagement/List" >客户管理</a></el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <el-form :inline="true" style="margin-top: 20px">

		<el-form-item label="客户名称">
			<el-input v-model="searchData.compName" placeholder="请选择客户名称"></el-input>
		</el-form-item>
		<el-form-item label="联系人姓名">
			<el-input v-model="searchData.contactPerson" placeholder="请选择联系人姓名"></el-input>
		</el-form-item>
		<el-form-item label="联系电话">
			<el-input v-model="searchData.contactPhone" placeholder="请选择联系电话"></el-input>
		</el-form-item>
		<el-form-item label="客户地址">
			<el-input v-model="searchData.compAddress" placeholder="请选择客户地址"></el-input>
		</el-form-item>
    <el-form-item label="创建时间">
      <el-date-picker
          v-model="searchData.createdDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button-group>
        <el-button type="success" @click="onSearch" v-hasPermission="'/Api/BusinessManagement/List'">查询</el-button>
        <el-button type="primary" @click="onExport" v-hasPermission="'/Api/BusinessManagement/List'">导出</el-button>
        <el-button type="primary" @click="onCreate" v-hasPermission="'/Api/BusinessManagement/Create'">增加</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <edit_view
  ref="editView"
      :params="edit_view"
      @getTableData="getTableData"
      @onClose="onClose"/>
  <info_view
      :params="info_view"
      @getTableData="getTableData"
      @onClose="onClose"/>

  <el-table
      :data="tableData"
      v-loading="loading"
      style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">
		<el-table-column key="compName" label="客户名称" prop="compName"></el-table-column>
		<el-table-column key="contactPerson" label="联系人姓名" prop="contactPerson"></el-table-column>
		<el-table-column key="contactPhone" label="联系电话" prop="contactPhone"></el-table-column>
		<el-table-column key="compAddress" label="客户地址" prop="compAddress"></el-table-column>
    <el-table-column key="createdDate" label="创建时间" prop="createdDate"></el-table-column>
    <el-table-column label="操作" min-width="110">
      <template #default="scope">
        <el-button-group>
        <el-button
            size="mini"
            type="success"
            @click="onInfo(scope.$index, scope.row)" v-hasPermission="'/Api/BusinessManagement/List'">查看
        </el-button>
        <el-button
            size="mini"
            type="primary"
            @click="onEdit(scope.$index, scope.row)" v-hasPermission="'/Api/BusinessManagement/Modify'">编辑
        </el-button>
        <el-popconfirm title="确定删除吗？" @confirm="onDelete(scope.row)" >
          <template #reference>
            <el-button
                size="mini"
                type="danger" v-hasPermission="'/Api/BusinessManagement/Remove'">删除
            </el-button>
          </template>
        </el-popconfirm>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination
      background
      :pager-count="11"
      layout="total, prev, pager, next, jumper"
      :total="pageData.total"
      :page-size="pageData.size"
      :current-page="pageData.current"
      @current-change="changePage">
  </el-pagination>

</template>

<script>

import axios from '@/utils/axios'
import edit_view from './Form'
import info_view from './Info'
import {ElMessage} from 'element-plus'


export default {
  data() {
    return {
      // 全局变量，用于获得配置信息等
      global: window.global,

      loading: false,
      // 编辑窗口组件
      edit_view: {
        show: false, // 是否显示
        type: "", // add or edit
        title: "", //标题
        data: {} //数据
      },
      // 查看窗口组件
      info_view: {
        show: false, // 是否显示
        type: "", // add or edit
        title: "", //标题
        data: {} //数据
      },
      // 分页信息
      pageData: window.global.config.page,
      // 搜索区内容
      searchData: {},
      searchOptions: {},
      // 表格数据
      tableData: [],
      
    }
  },
  components: {
    edit_view,// 编辑窗口组件
    info_view,// 查看窗口组件
    
  },
  created() {
    this.getTableData()
  },
  methods: {
    // 搜索事件
    onSearch() {
      this.getTableData()
    },
    // 导出事件
    onExport() {
      axios.exportExecl(`/BusinessManagement/Export`,`企业管理`, { //url: 接口地址
        params: this.searchData,
        responseType: `arraybuffer` //一定要写
      });
    },
    // 启动地图  ----------------121.54019847316344----------------------38.86825461399343
    mapFunc() {
      this.markerObj = []
      var num = 0
      var lng = 121.54019847316344;
      var lat = 38.86825461399343;
      var title = "故宫博物院";
      if (
          this.lat != null &&
          this.lat != undefined &&
          (this.lng != null) & (this.lng != undefined) &&
          this.lng != "" &&
          this.lat != ""
      ) {
        lat = this.lat;
        lng = this.lng;
        title = this.addressKeyword;
      }
      this.map = new BMapGL.Map("container");
      var point = new BMapGL.Point(lng, lat);
      this.map.centerAndZoom(point, 15);
      this.map.enableScrollWheelZoom(true);
      // 创建点标记
      // var marker = new BMapGL.Marker(point);
      // this.map.addOverlay(marker);
      // 解析地址
      var geoc = new BMapGL.Geocoder();
      // // 创建信息窗口
      var opts = {
        width: 200,
        height: 70,
      };
      // https://api.map.baidu.com/images/blank.gif
      // var infoWindow = new BMapGL.InfoWindow(title, opts);
      // // 点标记添加点击事件
      // marker.addEventListener("click", function() {
      //   this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
      //   console.log(".getPosition()", marker.getPosition());
      // });

      var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
      this.map.addControl(scaleCtrl);
      var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
      this.map.addControl(zoomCtrl);

      var that = this
      // 地图点击事件click。右键是rightclick。随意更换  markerObj是所有点的集合，取最后一个点的方法在 提交方法
      this.map.addEventListener('rightclick', function (e) {
        //清除地图上所有的覆盖物
        that.map.clearOverlays();
        var pt = e.latlng;
        var marker = new BMapGL.Marker(new BMapGL.Point(pt.lng, pt.lat));
        that.map.addOverlay(marker);
        geoc.getLocation(pt, function (rs) {
          var content = rs.address
          var infoWindow = new BMapGL.InfoWindow(content, opts);
          that.map.openInfoWindow(infoWindow, pt); // 开启信息窗口
        })
        // // num++
        // var marNum = 'marker' + num
        // var point = new BMapGL.Point(e.latlng.lng, e.latlng.lat)
        // marNum = new BMapGL.Marker(point);
        that.markerObj.unshift(marker)
        // that.map.addOverlay(marNum)
      });
    },
    // 增加事件
    onCreate() {
      this.edit_view.title = "新增";
      this.edit_view.type = 'add'
      this.edit_view.data = {}
      this.edit_view.show = true;
      this.$nextTick(() => {
        // 地图的启动方法
        this.$refs.editView.mapFunc()
        console.log('.vvvvv')
      })
    },
    // 查看事件
    onInfo(index, row) {
      this.info_view.title = "详情";
      this.info_view.data = row
      this.info_view.show = true;
    },
    // 删除事件
    onDelete(row) {
      this.loading = true
      axios.post('/BusinessManagement/Remove', {
        id: row.id,
        fackId: row.fackId
      }).then(() => {
        ElMessage.success('删除成功')
        this.getTableData()
      }).catch(() => {
        this.getTableData()
      })
    },
    // 编辑事件
    onEdit(index, row) {
      this.edit_view.title = "修改";
      this.edit_view.type = 'edit'
      this.edit_view.data = row;
      this.edit_view.show = true;
    },
    // 返回事件
    onClose() {
      this.edit_view.show = false;
      this.info_view.show = false;
    },
    // 获取列表
    getTableData() {
      this.loading = true
      var that = this

      axios.get('/BusinessManagement/List', {
        params: Object.assign({}, this.searchData, this.pageData)
      }).then(res => {
        that.tableData = res.data.list
        that.pageData.total = res.data.total
        that.loading = false
      }).catch(error => {
        that.loading = false
      })
    },
    // 分页事件
    changePage(page) {
      this.pageData.current = page
      this.getTableData()
    },
    
  }
}
</script>